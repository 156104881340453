<template>
  <svg
    viewBox="0 0 24 22"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="22"
  >
    <path
      d="M0.761595 1.94474C0.351505 1.94474 0 1.58924 0 1.15475C0 0.740013 0.351505 0.384521 0.761595 0.384521L2.75346 0.384521C2.77299 0.384521 2.81204 0.384521 2.83157 0.384521C3.53458 0.404271 4.15948 0.542518 4.68674 0.87826C5.27258 1.2535 5.7022 1.82624 5.93653 2.67547C5.93653 2.69522 5.93653 2.71497 5.95606 2.73472L6.15134 3.5247L23.2384 3.5247C23.668 3.5247 24 3.88019 24 4.29493C24 4.37393 23.9805 4.45293 23.9609 4.53193L21.9691 12.649C21.891 13.0045 21.5785 13.2415 21.227 13.2415L8.72905 13.2415C9.00244 14.2685 9.27583 14.8214 9.64687 15.0782C10.096 15.3744 10.8771 15.3942 12.1855 15.3744H12.205L21.0317 15.3744C21.4613 15.3744 21.7933 15.7299 21.7933 16.1447C21.7933 16.5792 21.4418 16.9149 21.0317 16.9149H12.205C10.5842 16.9346 9.58828 16.8951 8.78763 16.3619C7.96745 15.8089 7.53784 14.8609 7.10822 13.1427L4.49146 3.12971C4.49146 3.10996 4.49146 3.10996 4.47193 3.09021C4.35476 2.65572 4.15948 2.35948 3.88609 2.20148C3.61269 2.02373 3.24166 1.94474 2.81204 1.94474C2.79251 1.94474 2.77299 1.94474 2.75346 1.94474L0.761595 1.94474ZM18.7469 17.8234C19.7819 17.8234 20.6216 18.6726 20.6216 19.7193C20.6216 20.7661 19.7819 21.6153 18.7469 21.6153C17.712 21.6153 16.8723 20.7661 16.8723 19.7193C16.8723 18.6726 17.712 17.8234 18.7469 17.8234ZM10.5256 17.8234C11.5606 17.8234 12.4003 18.6726 12.4003 19.7193C12.4003 20.7661 11.5606 21.6153 10.5256 21.6153C9.49064 21.6153 8.65094 20.7661 8.65094 19.7193C8.65094 18.6726 9.49064 17.8234 10.5256 17.8234ZM6.58096 5.06517L8.31896 11.6813L20.6412 11.6813L22.262 5.06517L6.58096 5.06517Z"
    />
  </svg>
</template>
